(function(){
	"use strict";

	angular.module("app.services").factory('UserService', function( $auth, $http, $rootScope, $resource, $localStorage ){

		var service = {
			user: {},
			users: {},

			getUser: function (uuid) {
				$http({
						url: 'api/users/' + uuid,
						method: 'GET',
					})
					.then(function (response) {
							// Clean user data
							service.user = response.data.user;
							$rootScope.User = service.user;
							$rootScope.$broadcast('user.gotUser');
						},
						function (response) {
							// failed
						});
			},

			saveUser: function (user_data) {
				$http({
						url: 'api/users/update/' + user_data.uuid,
						method: 'PUT',
						data: {
							'first_name' : user_data.first_name,
							'last_name' : user_data.last_name,
							'email' : user_data.email,
						}
					})
					.then(function (response) {
							// Clean user data
							//service.user = response.data.user;
							$rootScope.$broadcast('user.savedUser');
						},
						function (response) {
							// failed
						});
			},

			verifyToken: function (user_data) {
				$http({
					url: 'api/auth/verify',
					method: 'POST',
					data: {
						'email' : user_data.email,
						'verify_token' : user_data.verify_token
					}
				})
				.then(function (response) {
							// Token verified
							service.verified = true;
							$rootScope.$broadcast('user.verifiedUser');
						},
						function (response) {
							// failed
							service.verified = false;
							$rootScope.$broadcast('user.verifiedUser');
						});

			},

			verifyResetToken: function (user_data) {
				$http({
					url: 'api/auth/verify_reset',
					method: 'POST',
					data: {
						'email' : user_data.email,
						'reset_token' : user_data.reset_token
					}
				})
				.then(function (response) {
							// Token verified
							service.verified = true;
							$rootScope.$broadcast('user.verifiedReset');
						},
						function (response) {
							// failed
							service.verified = false;
							$rootScope.$broadcast('user.verifiedReset');
						});

			},

			activate: function (user_data) {
				$http({
					url: 'api/auth/activate',
					method: 'POST',
					data: {
						'email' : user_data.email,
						'password' : user_data.password,
						'verify_token' : user_data.verify_token
					}
				})
				.then(function (response) {
							// Token verified
							service.activated = true;
							$rootScope.$broadcast('user.activatedUser');
						},
						function (response) {
							// failed
							service.activated = false;
							$rootScope.$broadcast('user.activatedUser');
						});

			},

			requestReset: function (email, callback) {
				$http({
					url: 'api/auth/request_reset',
					method: 'POST',
					data: {
						'email' : email,
					}
				})
				.then(function (response) {
							// Token created
							callback(response);
						},
						function (response) {
							// failed
							callback(response);
						});

			},
		};

		return service;
	});
})();
