(function (){
    "use strict";

    angular.module('app.controllers').controller('ReferralCtrl', function (
    		$scope,
    		$http,
    		$location,
    		$localStorage,
    		ReferralService,
    		DialogService
    	)
    {

    	if(!$localStorage.user) {
    		$location.url('/login');
    	}

    	$scope.user =  $localStorage.user;

        $scope.date = new Date();

		$scope.referral_data = {};
		$scope.referral_data.client_address_country = 'United Kingdom';

		$http.get('assets/json/countries.json').success(function(data) {
			$scope.countries = data;
		});

		$scope.showHelp = function(){
			return DialogService.fromTemplate('referral_help', $scope);
		};

		$scope.closeHelp = function(){
			return DialogService.hide();
		};

		$scope.submitReferral = function(){

			ReferralService.saveReferral($scope.referral_data, $scope.user.uuid);

			$scope.$on('referral.savedReferral', function() {
				$scope.referral_data = {};
				$scope.referralForm.$setUntouched();
				$scope.referralForm.$setPristine();
				return DialogService.alert('Referral Record Saved!', 'Your request has been sent');
			});
		};

    });

})();