(function (){
	"use strict";

	angular.module('app.controllers').controller('LogoutCtrl', function (
			$scope,
			$auth,
			$http,
			$location,
			$localStorage,
			ToastService
		)
	{

		if(!$localStorage.user) {
    		$location.url('/login');
    	}

		$scope.email = '';
		$scope.password = '';

		$scope.logout = function() {

			$localStorage.$reset();
			$location.url('/login');

		};

		$scope.cancel = function() {
			window.history.back();
		};

	});

})();