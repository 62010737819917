(function (){
	"use strict";

	angular.module('app.controllers').controller('SidebarCtrl', function (
			$scope,
			$state,
			$location,
			$localStorage,
			ToastService
		)
	{	
		$scope.showUserMenu = function() {
			if($localStorage.user) {
				return true;
			}

			return false;
		};

	});

})();