(function (){
    "use strict";

    angular.module('app.controllers').controller('QuestionCtrl', function (
    		$scope,
    		$http,
    		$location,
    		$localStorage,
    		UserService,
    		DialogService,
    		QuestionService
    	)
    {

    	if(!$localStorage.user) {
    		$location.url('/login');
    	}

    	$scope.question = '';

        $scope.date = new Date();

		$scope.submitQuestion = function(){

			QuestionService.saveQuestion($scope.question, $localStorage.user.uuid);

			$scope.$on('question.savedQuestion', function() {
				$scope.question = '';
				$scope.questionForm.$setUntouched();
				$scope.questionForm.$setPristine();
				return DialogService.alert('Question Sent!', 'Your question has been sent and will be dealt with shortly');
			});
		};

    });

})();