(function(){
    "use strict";

    angular.module('app.routes').config( function($stateProvider, $urlRouterProvider, $locationProvider, $authProvider, $httpProvider ) {

        var getView = function( viewName ){
            return '/views/app/' + viewName + '/' + viewName + '.html';
        };

        // Satellizer configuration that specifies which API
        // route the JWT should be retrieved from
        $authProvider.loginUrl = '/api/auth/login';

        $urlRouterProvider.otherwise('/');
        // use the HTML5 History API
        $locationProvider.html5Mode(true);

        $stateProvider
        .state('landing', {
            url: '/',
            views: {
                pageheader: {
                    templateUrl: getView('pageheader')
                },
                main: {
                    templateUrl: getView('books')
                },
                sidebar: {
                	templateUrl: getView('sidebar')
                }
            }
        })
        .state('books', {
            url: '/books',
            data: {auth: true},
            views: {
                pageheader: {
                    templateUrl: getView('pageheader')
                },
                main: {
                    templateUrl: getView('books')
                },
                sidebar: {
                	templateUrl: getView('sidebar')
                }
            }
        }).state('addbook', {
            url: '/books/add',
            data: {auth: true},
            views: {
                pageheader: {
                    templateUrl: getView('pageheader')
                },
                main: {
                    templateUrl: getView('books-add')
                },
                sidebar: {
                	templateUrl: getView('sidebar')
                }
            }
        }).state('editbook', {
            url: '/books/edit/:uuid',
            data: {auth: true},
            views: {
                pageheader: {
                    templateUrl: getView('pageheader')
                },
                main: {
                    templateUrl: getView('books-edit')
                },
                sidebar: {
                	templateUrl: getView('sidebar')
                }
            }
        }).state('categories', {
            url: '/categories',
            data: {auth: true},
            views: {
                pageheader: {
                    templateUrl: getView('pageheader')
                },
                main: {
                    templateUrl: getView('categories')
                },
                sidebar: {
                	templateUrl: getView('sidebar')
                }
            }
        }).state('shelves', {
            url: '/shelves',
            data: {auth: true},
            views: {
                pageheader: {
                    templateUrl: getView('pageheader')
                },
                main: {
                    templateUrl: getView('shelves')
                },
                sidebar: {
                	templateUrl: getView('sidebar')
                }
            }
        }).state('account', {
            url: '/account',
            views: {
                pageheader: {
                    templateUrl: getView('pageheader')
                },
                main: {
                    templateUrl: getView('account')
                },
                sidebar: {
                	templateUrl: getView('sidebar')
                }
            }    
        }).state('login', {
            url: '/login',
            views: {
                pageheader: {
                    templateUrl: getView('pageheader')
                },
                main: {
                    templateUrl: getView('login')
                },
                sidebar: {
                	templateUrl: getView('sidebar')
                }
            }
        }).state('logout', {
            url: '/logout',
            views: {
                pageheader: {
                    templateUrl: getView('pageheader')
                },
                main: {
                    templateUrl: getView('logout')
                },
                sidebar: {
                	templateUrl: getView('sidebar')
                }
            }
        }).state('verify', {
            url: '/verify_account/:verify_token',
            views: {
                pageheader: {
                    templateUrl: getView('pageheader')
                },
                main: {
                    templateUrl: getView('verify')
                },
                sidebar: {
                	templateUrl: getView('sidebar')
                }
            }
        }).state('request-reset', {
            url: '/request-reset',
            views: {
                pageheader: {
                    templateUrl: getView('pageheader')
                },
                main: {
                    templateUrl: getView('requestreset')
                },
                sidebar: {
                	templateUrl: getView('sidebar')
                }
            }
        }).state('process-reset', {
            url: '/process-reset/:reset_token',
            views: {
                pageheader: {
                    templateUrl: getView('pageheader')
                },
                main: {
                    templateUrl: getView('processreset')
                },
                sidebar: {
                	templateUrl: getView('sidebar')
                }
            }
        });
    });
})();
