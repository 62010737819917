(function (){
	'use strict';
	/**
	 * @ngdoc filter
	 * @name app.filter:string
	 * @function
	 * @description
	 * # string
	 * Filter in the app.
	 */
	angular.module('app')
	.filter('startFrom', function() {
	    return function(input, start) {
			if (!angular.isArray(input)) {
	            return 1;
	        }
	        start = +start; //parse to int
	        return input.slice(start);
	    };
	});

})();
