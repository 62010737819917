(function(){
	"use strict";

	angular.module("app.services").factory('LoginService', function( $auth, $http, $resource, $localStorage ){

		var user;
		var service = {
			user: {},

			login: function(userLogin, callback) {
				$auth.login(userLogin)
					.then(function (response) {
						// We've got the token, now save the user data
						//service.user = response.data.user;
	                    $localStorage.user = response.data.user;
	                    callback(response);
					})
					.catch(function(response) {
						callback(response);
					});
			}
		};

		return service;
	});
})();
