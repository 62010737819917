(function (){
	"use strict";

	angular.module('app.controllers').controller('VerifyCtrl', function (
			$scope,
			$state,
			$rootScope,
			$auth,
			$http,
			$location,
			$localStorage,
			ToastService,
			UserService,
			LoginService
		)
	{

		$scope.email = '';
		$scope.password = '';
		$scope.confirm_password = '';
		$scope.verified = false;
		$scope.errorMessage = '';

		$scope.$on('user.verifiedUser', function() {
			$scope.verified = UserService.verified;

			if($scope.verified === false) {
				ToastService.show('There was an error validating your email, please check and try again.');
			}
		});

		$scope.$on('user.activatedUser', function() {
			$scope.activated = UserService.activated;

			if($scope.activated === false) {
				ToastService.show('There was an error activating your account, please check and try again.');
				$scope.errorMessage = 'There was an error activating your account, please check and try again.';
				$scope.verified = false;
				return;
			}

			var handleLogin = function (response) {
				switch(response.status) {
					case 200:
						ToastService.show('Logged in successfully.');
						if($localStorage.user.company.type === 'admin') {
							$location.url('/admin');
						} else {
							$location.url('/dashboard/');
						}
						break; // 200
					default:
						ToastService.show('There was an error logging in.');
				}
			};

			var user_data = {
				email : $scope.email.replace(/</g, '&lt;').replace(/>/g, '&gt;'),
				password : $scope.password
			};

			LoginService.login(user_data, handleLogin);

		});

		$scope.verify = function() {

			var user_data = {
				email : $scope.email.replace(/</g, '&lt;').replace(/>/g, '&gt;'),
				verify_token : $state.params.verify_token
			};

			UserService.verifyToken(user_data);
		};

		$scope.activate = function() {

			if (!angular.equals($scope.password, $scope.confirm_password)) {
				ToastService.show('Your passwords do not match.');
				$scope.errorMessage = "Your passwords do not match.";
				return;
			}

			var user_data = {
				email : $scope.email.replace(/</g, '&lt;').replace(/>/g, '&gt;'),
				password : $scope.password.replace(/</g, '&lt;').replace(/>/g, '&gt;'),
				verify_token : $state.params.verify_token
			};

			UserService.activate(user_data);
		};

	});

})();