(function (){
    "use strict";

    angular.module('app.controllers').controller('AccountCtrl', function (
    		$scope,
			$state,
    		$http,
    		$location,
    		$localStorage,
    		UserService,
			BookService,
			CategoryService,
			ShelfService,
    		DialogService
    	)
    {

    	if(!$localStorage.user) {
    		$location.url('/login');
    	}

    	$scope.user =  $localStorage.user;

        $scope.date = new Date();

		$scope.uuid = $state.params.uuid;

		BookService.getBook($scope.uuid);

		$scope.$on('book.gotBook', function() {
			$scope.book = BookService.book;
		});

		CategoryService.getCategories();

		$scope.$on('category.gotCategories', function() {
			$scope.categories = CategoryService.categories;
		});

		ShelfService.getShelves();

		$scope.$on('shelf.gotShelves', function() {
			$scope.shelves = ShelfService.shelves;
		});
		
		$scope.updateBook = function(){

			BookService.updateBook($scope.book);

			$scope.$on('book.updatedBook', function() {
				return DialogService.alert('Book Details Saved!', 'The book has been updated');
			});
		};

    });

})();