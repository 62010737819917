(function (){
	"use strict";

	angular.module('app.controllers').controller('AdminCtrl', function (
			$scope,
			$state,
			$rootScope,
			$location,
			$localStorage,
			$filter,
			ToastService,
			UserService,
			CompanyService,
			MailChimpService
		)
	{

		$scope.today = new Date();
		$scope.user = {};
		$scope.user.uuid = '';
		$scope.filters = [];
		$scope.introducers = [];
		$scope.account_types = [];
		$scope.pageSize = 10;
		$scope.currentPage = 1;
		$scope.total = 0;

		if(!$localStorage.user) {
			$location.url('/login');
		}

		if($localStorage.user.company.type !== 'admin') {
			$location.url('/dashboard');
		}

		$scope.user = $localStorage.user;

		CompanyService.getIntroducers($scope.filters);

		$scope.$on('company.gotIntroducers', function() {
			$scope.introducers = CompanyService.introducers;
			$scope.total = $scope.introducers.length;
		});

		$scope.$on('company.gotIntroducerError', function() {
			ToastService.show('There was an error retrieving the data, please login again and retry.');
			$localStorage.$reset();
			$location.url('/login');
		});

		CompanyService.getAccountTypes();

		$scope.$on('company.gotAccountTypes', function() {
			$scope.account_types = CompanyService.account_types;
		});

		$scope.updateIntroducers = function() {
			var filters = {
	            'q': $scope.q,
	            'account_type' : $scope.account_type
	        };
			CompanyService.getIntroducers(filters);
		};
		
	});

})();