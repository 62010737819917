(function (){
    "use strict";

    angular.module('app.controllers').controller('SignupCtrl', function (
    		$scope,
    		$http,
    		$location,
    		$localStorage,
    		SignUpService,
    		DialogService
    	)
    {

    	if($localStorage.user) {
    		$location.url('/dashboard/');
    	}

        $scope.date = new Date();

		$scope.application_data = {};
		$scope.application_data.address_country = 'United Kingdom';

		$http.get('assets/json/countries.json').success(function(data) {
			$scope.countries = data;
		});

		$scope.submitSignUp = function(){

			if(!$scope.application_data.agree_credit_check || $scope.application_data.agree_credit_check === false ) {
				DialogService.alert('Application Error', 'You must agree to Clifton Asset Management undertaking a credit check');
				return;
			}

			SignUpService.saveSignUp($scope.application_data);

			$scope.$on('signup.savedSignUp', function() {
				$scope.application_data = {};
				$scope.applicationForm.$setUntouched();
				$scope.applicationForm.$setPristine();
				return DialogService.alert('Application Sent!', 'Your request has been submitted');
			});
		};

    });

})();