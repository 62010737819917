(function(){
	"use strict";

	angular.module("app.services").factory('CategoryService', function( $auth, $http, $rootScope, $resource, $localStorage ){

		var service = {
			category: {},
			categories: [],

			/** Get a list of Categories for the Admin Dashboard */
			getCategories: function () {

				$http({
						url: 'api/categories',
						method: 'GET',
					})
					.then(function (response) {
							// Clean user data
							service.categories = response.data.categories;
							$rootScope.$broadcast('category.gotCategories');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('category.gotCategoriesError');
						});
			},

			/** Get details of specified category */
			getCategory: function (category) {

				$http({
						url: 'api/categories/' + category.uuid,
						method: 'GET',
					})
					.then(function (response) {
							// Clean user data
							service.category = response.data.category;
							$rootScope.$broadcast('category.gotCategory');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('category.gotCategoryError');
						});
			},

            /** Update the details of specified category */
			updateCategory: function (category) {

				$http({
						url: 'api/categories/' + category.uuid,
						method: 'PUT',
                        data: {
							'category' : category,
						}
					})
					.then(function (response) {
							// Clean user data
							service.category = response.data.category;
							$rootScope.$broadcast('category.updatedCategory');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('category.updatedCategoryError');
						});
			},

            /** Create a new category */
			createCategory: function (category) {

				$http({
						url: 'api/categories',
						method: 'POST',
                        data: {
							'category' : category,
						}
					})
					.then(function (response) {
							// Clean user data
							service.category = response.data.category;
							$rootScope.$broadcast('category.createdCategory');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('category.createdCategoryError');
						});
			},

            /** Update the details of specified category */
			deleteCategory: function (category) {

				$http({
						url: 'api/categories/' + category.uuid,
						method: 'DELETE',
					})
					.then(function (response) {
							// Clean user data
							$rootScope.$broadcast('category.deletedCategory');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('category.deletedCategoryError');
						});
			},
		};

		return service;
	});
})();
