(function (){
	"use strict";

	angular.module('app.controllers').controller('DashboardCtrl', function (
			$scope,
			$state,
			$rootScope,
			$window,
			$location,
			$localStorage,
			$filter,
			ToastService,
			UserService,
			OpportunityService,
			MailChimpService
		)
	{

		$scope.today = new Date();
		$scope.opportunities = [];
		$scope.children = [];
		$scope.user = {};
		$scope.user.uuid = '';
		$scope.data = [];
		$scope.newsletters = [];

		if(!$localStorage.user) {
			$location.url('/login');
		}

		$scope.user = $localStorage.user;

		if($state.params.user_uuid) {
			UserService.getUser($state.params.user_uuid);
		}
		$scope.$on('user.gotUser', function() {
			$scope.user = UserService.user;
			OpportunityService.getOpportunities($scope.user.uuid);
		});

		OpportunityService.getOpportunities($scope.user.uuid);
		$scope.$on('opportunity.gotOpportunity', function() {
			$scope.opportunities = OpportunityService.opportunities;
			$scope.children = OpportunityService.children;

			// Calculate the totals for Referrals
			$scope.referrals_won = $filter('filter')($scope.opportunities, { status: "Won", first_deal:1 }).length;
			$scope.referrals_lost = $filter('filter')($scope.opportunities, { status: "Lost", first_deal:1 }).length;
			$scope.referrals_pending = $filter('filter')($scope.opportunities, { status: "Pipeline", first_deal:1 }).length;

			// Calculate the totals for Further Business
			$scope.fb_won = $filter('filter')($scope.opportunities, { status: "Won", first_deal:0 }).length;
			$scope.fb_lost = $filter('filter')($scope.opportunities, { status: "Lost", first_deal:0 }).length;
			$scope.fb_pending = $filter('filter')($scope.opportunities, { status: "Pipeline", first_deal:0 }).length;

			// Put the totals into the graph data
			$scope.graph_data = [
				$scope.referrals_won,
				$scope.referrals_pending,
				$scope.referrals_lost,
				$scope.fb_won,
				$scope.fb_pending,
				$scope.fb_lost
			];
		});

		$scope.$on('opportunity.gotOpportunityError', function() {
			ToastService.show('There was an error retrieving the data, please login again and retry.');
			$localStorage.$reset();
			$location.url('/login');
		});

		$scope.updateOpportunities = function() {
			var filters = {
	            'start_date': angular.isUndefined($scope.start_date) ? '' : moment($scope.start_date).format('YYYY-MM-DD'),
	            'end_date': angular.isUndefined($scope.end_date) ? '' : moment($scope.end_date).format('YYYY-MM-DD'),
	            'company_uuid' : $scope.company_uuid
	        };
			OpportunityService.getOpportunities($scope.user.uuid, filters);
		};

		// Set the pagination variables for all tables
		$scope.referralsWonPageSize = 10;
		$scope.referralsWonCurrentPage = 1;

		$scope.referralsLostPageSize = 10;
		$scope.referralsLostCurrentPage = 1;

		$scope.referralsPendingPageSize = 10;
		$scope.referralsPendingCurrentPage = 1;

		$scope.fbWonPageSize = 10;
		$scope.fbWonCurrentPage = 1;

		$scope.fbLostPageSize = 10;
		$scope.fbLostCurrentPage = 1;

		$scope.fbPendingPageSize = 10;
		$scope.fbPendingCurrentPage = 1;

		$scope.graph_labels = [
			"New Business Won",
			"New Business Pending",
			"New Business Lost",
			"Further Business Won",
			"Further Business Pending",
			"Further Business Lost",
		];

		$scope.graph_colours = [
			"#6A1B9A",
			"#9C27B0",
			"#CE93D8",
			"#FF8F00",
			"#FFC107",
			"#FFE082",
		];

		$scope.graph_options = {
            legend: {
				display: true,
				position: 'bottom'
			},
			tooltips: {
				enabled: true
			},
			/*animation: {
				onComplete: function () {
					this.showTooltip(this.segments, true);
				}
			},*/
		};

		$scope.bar_options = {
			scales: {
				xAxes: [{
					display: false
				}],
				yAxes: [{
					display: true
				}]
			}
		};

		MailChimpService.getNewsletters();
		$scope.$on('mailchimp.gotNewsletters', function() {
			$scope.newsletters = MailChimpService.newsletters;
		});

		$scope.crmLink = function(uuid, record_type) {
			if($localStorage.user.company.type !== 'admin') {
				return;
			}
			var crm_url = 'https://internalcrm.cliftonasset.co.uk/CliftonAssetManagement/main.aspx?etn=' +
							record_type +
							'&pagetype=entityrecord&id=%7B' +
							uuid + '%7D';

			$window.open(crm_url,'_blank');
		};

		$scope.showAdminIcon = function() {
			if($localStorage.user && $localStorage.user.company.type === 'admin') {
				return true;
			}

			return false;
		};

	});

})();