(function (){
    "use strict";

    angular.module('app.controllers').controller('AccountCtrl', function (
    		$scope,
    		$http,
    		$location,
    		$localStorage,
    		UserService,
    		DialogService
    	)
    {

    	if(!$localStorage.user) {
    		$location.url('/login');
    	}

    	$scope.user =  $localStorage.user;

        $scope.date = new Date();

		$scope.submitAccount = function(){

			UserService.saveUser($scope.user);

			$scope.$on('user.savedUser', function() {
				return DialogService.alert('Acount Details Saved!', 'Your account has been updated');
			});
		};

    });

})();