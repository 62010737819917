(function (){
	"use strict";

	angular.module('app.controllers').controller('LoginCtrl', function (
			$scope,
			$auth,
			$http,
			$location,
			$localStorage,
			ToastService,
			LoginService
		)
	{

		if($localStorage.user) {
    		$location.url('/books/');
    	}

		$scope.email = '';
		$scope.password = '';

		$scope.login = function() {

			var handleLogin = function (response) {
				switch(response.status) {
					case 200:
						ToastService.show('Logged in successfully.');
						$location.url('/books/');
						break; // 200
					case 401:
						ToastService.show('There was an error logging in.');
						break; // 401
					default:
						ToastService.show('There was an error logging in.');
				}
			};

			/** Check for injection */
			var user = {
				email : $scope.email.replace(/</g, '&lt;').replace(/>/g, '&gt;'),
				password : $scope.password
			};

			LoginService.login(user, handleLogin);
		};

	});

})();