(function(){
    "use strict";

    angular
    	.module('app.controllers')
    	.controller('LandingCtrl', [
    		'$scope',
    		LandingCtrl

		]);

	function LandingCtrl($scope) {

		$scope.greeting = 'Alrightee!';
	}

})();