(function(){
	"use strict";

	angular
		.module('app.controllers')
		.controller('PageheaderCtrl', [
			'$scope',
			PageheaderCtrl

		]);

	function PageheaderCtrl($scope) {



	}

})();