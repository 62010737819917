(function(){
"use strict";

var app = angular.module('app',
        [
        'app.controllers',
        'app.filters',
        'app.services',
        'app.directives',
        'app.routes',
        'app.config'
        ]);

    angular.module('app.routes', ['ui.router']);
    angular.module('app.controllers', [
	    	'ngMaterial',
	    	'ui.router',
	    	'restangular',
	    	'chart.js',
	    	'md.data.table',
	    	'satellizer',
	    	'ngStorage',
	    	'angularMoment'
    	]);
    angular.module('app.filters', []);
    angular.module('app.services', ['ngResource', 'angular-loading-bar']);
    angular.module('app.directives', []);
    angular.module('app.config', ['ngMaterial']);
})();
