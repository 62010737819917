(function(){
	"use strict";

	angular.module("app.services").factory('ShelfService', function( $auth, $http, $rootScope, $resource, $localStorage ){

		var service = {
			shelf: {},
			shelves: [],

			/** Get a list of Shelves for the Admin Dashboard */
			getShelves: function () {

				$http({
						url: 'api/shelves',
						method: 'GET',
					})
					.then(function (response) {
							// Clean user data
							service.shelves = response.data.shelves;
							$rootScope.$broadcast('shelf.gotShelves');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('shelf.gotShelvesError');
						});
			},

			/** Get details of specified shelf */
			getShelf: function (shelf) {

				$http({
						url: 'api/shelves/' + shelf.uuid,
						method: 'GET',
					})
					.then(function (response) {
							// Clean user data
							service.shelf = response.data.shelf;
							$rootScope.$broadcast('shelf.gotShelf');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('shelf.gotShelfError');
						});
			},

            /** Update the details of specified shelf */
			updateShelf: function (shelf) {

				$http({
						url: 'api/shelves/' + shelf.uuid,
						method: 'PUT',
                        data: {
							'reference' : reference,
						}
					})
					.then(function (response) {
							// Clean user data
							service.shelf = response.data.shelf;
							$rootScope.$broadcast('shelf.updatedShelf');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('shelf.updatedShelfError');
						});
			},

            /** Create a new shelf */
			createShelf: function (shelf) {

				$http({
						url: 'api/shelves',
						method: 'POST',
                        data: {
							'reference' : reference,
						}
					})
					.then(function (response) {
							// Clean user data
							service.shelf = response.data.shelf;
							$rootScope.$broadcast('shelf.createdShelf');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('shelf.createdShelfError');
						});
			},

            /** Update the details of specified shelf */
			deleteShelf: function (shelf) {

				$http({
						url: 'api/shelves/' + shelf.uuid,
						method: 'DELETE',
					})
					.then(function (response) {
							// Clean user data
							$rootScope.$broadcast('shelf.deletedShelf');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('shelf.deletedShelfError');
						});
			},
		};

		return service;
	});
})();
