(function (){
	"use strict";

	angular.module('app.controllers').controller('RequestResetCtrl', function (
			$scope,
			$auth,
			$http,
			$location,
			$localStorage,
			ToastService,
			UserService
		)
	{

		if($localStorage.user) {
    		if($localStorage.user.company.type === 'admin') {
				$location.url('/admin');
			} else {
				$location.url('/dashboard/');
			}
    	}

		$scope.email = '';
		$scope.password = '';
		$scope.sent = false;

		$scope.requestReset = function() {

			var handleRequest = function (response) {
				switch(response.status) {
					case 200:
						ToastService.show('Please check your email.');
						$scope.sent = true;
						break; // 200
					case 401:
						ToastService.show('There was an error.');
						break; // 401
					default:
						ToastService.show('There was an error.');
				}
			};

			/** Check for injection */
			var email = $scope.email.replace(/</g, '&lt;').replace(/>/g, '&gt;');

			UserService.requestReset(email, handleRequest);
		};

	});

})();