(function(){
	"use strict";

	angular.module("app.services").factory('BookService', function( $auth, $http, $rootScope, $resource, $localStorage ){

		var service = {
			book: {},
			books: [],

			/** Get a list of Books for the Admin Dashboard */
			getBooks: function (filters) {

				$http({
						url: 'api/books',
						method: 'GET',
						params: filters,
					})
					.then(function (response) {
							// Clean user data
							service.books = response.data.books;
							$rootScope.$broadcast('book.gotBooks');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('book.gotBooksError');
						});
			},

			/** Get details of specified book */
			getBook: function (uuid) {

				$http({
						url: 'api/books/' + uuid,
						method: 'GET',
					})
					.then(function (response) {
							// Clean user data
							service.book = response.data.book;
							$rootScope.$broadcast('book.gotBook');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('book.gotBookError');
						});
			},

            /** Update the details of specified book */
			updateBook: function (book) {

				$http({
						url: 'api/books/' + book.uuid,
						method: 'PUT',
                        data: {
							'book' : book,
						}
					})
					.then(function (response) {
							// Clean user data
							service.book = response.data.book;
							$rootScope.$broadcast('book.updatedBook');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('book.updatedBookError');
						});
			},

            /** Create a new book */
			createBook: function (book) {

				$http({
						url: 'api/books',
						method: 'POST',
                        data: {
							'book' : book,
						}
					})
					.then(function (response) {
							// Clean user data
							service.book = response.data.book;
							$rootScope.$broadcast('book.createdBook');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('book.createdBookError');
						});
			},

            /** Update the details of specified book */
			deleteBook: function (uuid) {

				$http({
						url: 'api/books/' + uuid,
						method: 'DELETE',
					})
					.then(function (response) {
							// Clean user data
							$rootScope.$broadcast('book.deletedBook');
						},
						function (response) {
							service.error = response.data.error;
							$rootScope.$broadcast('book.deletedBookError');
						});
			},
		};

		return service;
	});
})();
