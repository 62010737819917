(function (){
	"use strict";

	angular.module('app.controllers').controller('BooksCtrl', function (
			$scope,
			$state,
			$rootScope,
			$location,
			$localStorage,
			$filter,
			$mdDialog,
			ToastService,
			UserService,
			BookService,
			CategoryService,
			ShelfService,
			DialogService
		)
	{

		$scope.today = new Date();
		$scope.user = {};
		$scope.user.uuid = '';
		$scope.filters = [];
		$scope.books = [];
		$scope.categories = [];
		$scope.shelves = [];
		$scope.total = 0;

		$scope.filters = {
			order: 'title',
			limit: 50,
			page: 1,
			title: null,
			author: null,
			category_uuid: null,
			shelf_uuid: null,
			year: null,
			shelf: null,
		};

		$scope.clearFilters = function() {
			$scope.filters = {
				order: 'title',
				limit: 50,
				page: 1,
				title: null,
				author: null,
				category_uuid: null,
				shelf_uuid: null,
				year: null,
				shelf: null,
			};
			BookService.getBooks($scope.filters);
		};

		$scope.user = $localStorage.user;

		BookService.getBooks($scope.filters);

		$scope.$on('book.gotBooks', function() {
			$scope.books = BookService.books.data;
			$scope.total = BookService.books.count;
		});

		$scope.$on('book.gotBookError', function() {
			ToastService.show('There was an error retrieving the data, please login again and retry.');
			$localStorage.$reset();
			$location.url('/login');
		});

		CategoryService.getCategories();

		$scope.$on('category.gotCategories', function() {
			$scope.categories = CategoryService.categories;
		});

		ShelfService.getShelves();

		$scope.$on('shelf.gotShelves', function() {
			$scope.shelves = ShelfService.shelves;
		});

		$scope.refreshBooks = function() {
			BookService.getBooks($scope.filters);
		};

		$scope.deleteBook = function(uuid) {
			var confirm = $mdDialog.confirm()
				.title('Are you sure?')
				.textContent('Deleting a book is permanent.')
				.ariaLabel('Delete Book')
				.ok('do it!')
				.cancel('oh no don\'t!');

			$mdDialog.show(confirm).then(function() {
				BookService.deleteBook(uuid);
				$scope.$on('book.deletedBook', function() {
					BookService.getBooks($scope.filters);
					return DialogService.alert('Book Deleted!', 'The book has been deleted');
				});
			}, function() {
				
			});
		};
		
	});

})();